import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundErrorComponent } from './not-found-error/not-found-error.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { RouterModule } from '@angular/router';
import { PermissionErrorComponent } from './permission-error/permission-error.component';

@NgModule({
  declarations: [NotFoundErrorComponent, ServerErrorComponent, PermissionErrorComponent],
  imports: [CommonModule, RouterModule],
  exports: [NotFoundErrorComponent],
})
export class ErrorModule {}
