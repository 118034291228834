<div class="layout-boxed content">
  <div class="row">
    <div class="col-12 text-align-center mt-100 mb-100">
      <div class="message-title">
        500
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-align-center mb-100 message">
      This is somewhat embarrassing, isn’t it? Something serious went wrong. Our team has been
      notified and we will resolve this issue as soon as possible.
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-align-center mb-100 message">
      <a class="btn btn-primary" [routerLink]="['/']" routerLinkActive="router-link-active"
        >Take me back home</a
      >
    </div>
  </div>
</div>
