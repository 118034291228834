<div class="layout-boxed content">
  <div class="row">
    <div class="col-12 text-align-center mt-100 mb-100">
      <div class="message-title">
        401
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-align-center mb-100 message">
      Sorry but you do not have permissions to access this page.
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-align-center mb-100 message">
      <a class="btn btn-primary" [routerLink]="['/']" routerLinkActive="router-link-active"
        >Take me back home</a
      >
    </div>
  </div>
</div>
