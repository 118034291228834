<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ titleDisplayKey }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="messages.length == 1">{{ messages[0] }}</div>
  <div *ngIf="messages.length > 1">
    <div *ngFor="let messageItem of messages">{{ messageItem }}</div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="submit" (click)="activeModal.close('Save click')">OK</button>
</div>
